.over-hidden {
  overflow: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.color-default {
  &,
  &:hover {
    color: inherit;
  }
}

.section {
  border-top: 1px solid $border-color;
  &:first-child {
    border-top: 0;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;
}
