.introduce {
  &-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;
  }
  &-content {
    p {
      text-align: justify;
    }
  }
}
