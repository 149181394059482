.ct-info {
  &__title {
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  &__content {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 20px;
    p {
      margin-bottom: 0;
    }
    a {
      color: inherit;
      &:hover {
        color: $primary;
      }
    }
  }
  &__map {
    height: 251px;
  }
}
