.breadcrumb {
  margin-bottom: 0;
  border-bottom: 1px solid $border-color;
}
.breadcrumb-item {
  a {
    color: inherit;
    &:hover {
      color: $primary;
    }
  }
}
