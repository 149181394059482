.pagination {
  margin-bottom: 0;
}
.page-link {
  border-radius: .25rem !important;
  color: $gray-800;
  padding: 8px 16px;
}
.page-item {
  margin-right: 5px;
  &:last-child {
    margin-right: 0;
  }
  &.active .page-link {
    background-color: $gray-800;
    border-color: $gray-800;
  }
}
