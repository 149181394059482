.aside {
  &__block {
    margin-bottom: 25px;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 1px solid $border-color;
    position: relative;
    padding-bottom: 4px;
    margin-bottom: 10px;
    &::after {
      content: '';
      display: block;
      width: 80px;
      border-bottom: 1px solid $primary;
      position: absolute;
      bottom: -1px;
      left: 0;
    }
  }
}

.as-video-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  max-height: 78px;
  overflow: auto;
  a {
    display: block;
    font-size: 14px;
    line-height: 26px;
    overflow: hidden;
    height: 26px;
    text-overflow: ellipsis;
    color: inherit;
    &:hover {
      color: $primary;
      text-decoration: none;
    }
    .fa {
      color: #f00;
    }
  }
}

.sidebar {
  &__header {
    display: flex;
    min-height: 40px;
    background-color: #f9f8f8;
  }
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: $white;
    padding: 0 15px 0 10px;
    line-height: 40px;
    background-color: $primary;
    margin-bottom: 0;
  }
  &__body {
    padding: 15px;
    border: 1px solid $border-color;
    border-top: 0;
  }
  &__more {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    text-decoration: underline;
  }
  &__list {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  &__list-item {
    padding: 10px 0;
    border-bottom: 1px solid $border-color;
    &:last-child {
      border-bottom: 0;
    }
  }
  &__news {
    font-size: 14px;
    position: relative;
    &::before {
      content: '\f105';
      font-family: 'fontawesome';
      position: absolute;
      top: 0;
      left: -10px;
      font-size: 12px;
      color: #999;
    }
    &:hover {
      color: $primary;
      font-weight: 600;
    }
    &:hover::before {
      color: $primary;
    }
  }
  &__iwrap {
    @include iwrap(67%);
  }
  &__news-title {
    margin: 8px 0;
    font-size: 14px;
    font-weight: 600;
    &:hover {
      color: $primary;
    }
  }
  &__news-desc {
    @include max-line(3);
    text-align: justify;
  }
  &__news-info {
    font-size: 14px;
    color: #999;
  }
}
