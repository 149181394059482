.gallery-slider {
  width: 920px;
  max-width:  100%;
  margin: 0 auto 14px;
}
.thumb-slider {
  width: 550px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding: 0 30px;
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    &:hover {
      color: $primary;
    }
    &:focus {
      outline: 0;
    }
  }
  &__next {
    left: auto;
    right: 0;
  }
  .swiper-slide {
    cursor: pointer;
  }
}
