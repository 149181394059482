.footer {
  background-color: $blue-lighter;
  color: $white;
}
.f-contact {
  font-size: 14px;
  p {
    margin-bottom: 9px;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
    }
  }
}
.f-social {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-top: 27px;
  margin-bottom: 0;
  padding-left: 0;
  li {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    display: inline-flex;
    width: 40px;
    height: 40px;
    position: relative;
    color: $white;
    &:hover {
      color: $white;
    }
  }
  .fa {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 1px solid $white;
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 991px) {
    justify-content: flex-start;
    margin-top: 10px;
  }
}
.f-copyright {
  text-align: center;
  padding: 10px 0;
}
