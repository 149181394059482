.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  &-item {
    margin: 4px 0 8px;
    position: relative;
  }
  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  &-item:hover > &-link {
    background-color: rgba($primary, 0.05);
  }
  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }
  }
  &-sub {
    display: none;
  }
  &-sub &-link {
    padding-left: 40px;
  }
  &-sub-2 &-link {
    padding-left: 60px;
  }
  &-sub-3 &-link {
    padding-left: 80px;
  }
}

@each $key, $val in $grid-breakpoints {
  @media (min-width: #{$val}) {
    // menu-float
    .menu {
      &-float-#{$key} {
        min-width: 160px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        position: absolute;
        z-index: 1;
        top: -4px;
        left: calc(100% + 4px);
        border-radius: 4px;
        transform-origin: 0 4px;
        background-color: $white;
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 100%;
        }
        &.menu-float-right {
          left: auto;
          right: calc(100% + 4px);
          transform-origin: 100% 4px;
          &::before {
            right: auto;
            left: 100%;
          }
        }
      }
      &-item-group:hover > &-float-#{$key} {
        display: block;
        animation: menu-float-show .2s ease-out;
      }
      &-float-#{$key} > &-item > &-link {
        padding-left: 20px;
      }
    }

    // menu-expand
    .menu-expand-#{$key} {
      display: flex;
      & > .menu-item {
        margin: 0;
      }
      & .menu-item-group > .menu-link {
        padding-right: 40px;
      }
      .menu-item-group:hover > .menu-sub {
        display: block;
        animation: menu-float-show .2s ease-out;
      }
      .menu-sub {
        min-width: 160px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.15);
        position: absolute;
        z-index: 2;
        top: -4px;
        left: calc(100% + 4px);
        border-radius: 4px;
        transform-origin: 0 4px;
        background-color: $white;
        &::before {
          content: '';
          display: block;
          width: 8px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 100%;
        }
        &.menu-sub-right {
          left: auto;
          right: calc(100% + 4px);
          transform-origin: 100% 0;
          &::before {
            right: auto;
            left: 100%;
          }
        }
      }
      .menu-sub .menu-link {
        padding-left: 20px;
      }

      & > .menu-item-group > .menu-sub {
        transform-origin: 0 0;
        top: calc(100% + 4px);
        left: 0;
        &::before {
          width: 100%;
          height: 8px;
          right: auto;
          left: 0;
          top: auto;
          bottom: 100%;
        }
      }
    }
  }
}

@keyframes menu-float-show {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
