.header {
  background-color: $blue-lighter;
  position: relative;
  z-index: 100;
  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 12px 0 13px;
  }
  &__logo {
    display: inline-flex;
    img {
      height: 75px;
    }
  }
  &__logo-2 {
    display: inline-flex;
    img {
      height: 49px;
    }
  }
  &__text {
    white-space: nowrap;
    padding: 12px 30px;
    p {
      font-size: 20px;
      line-height: 1.2;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: $white;
      margin-bottom: 0;
      padding: 2px 0;
      &:nth-child(2) {
        font-weight: 400;
        color: #fff600;
        font-size: 18px;
      }
    }
  }
  @media (max-width: 1199px) {
    &__inner {
      flex-direction: column;
    }
  }
  @media (max-width: 991px) {
    &__text {
      padding: 15px 0;
      p {
        font-size: 16px;
        white-space: normal;
        padding: 4px 0;
        &:nth-child(2) {
          font-size: 14px;
        }
      }
    }
  }
}

.h-lang {
  display: flex;
  position: absolute;
  top: 10px;
  right: 0;
  &__item {
    display: flex;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    img {
      height: 19px;
    }
  }
  @media (max-width: 1199px) {
    top: calc(100% + 23px);
    transform: translateY(-50%);
    &__item {
      img {
        height: 23px;
      }
    }
  }
}

.navigation {
  background-color: $primary;
  .menu.menu-root  > .menu-item > .menu-link {
    text-transform: uppercase;
  }
  @media (min-width: 1200px) {
    .menu.menu-root {
      width: 100%;
      justify-content: space-between;
    }
    .menu.menu-root > .menu-item {
      & > .menu-link {
        color: $white;
        padding-top: 3px;
        padding-bottom: 3px;
      }
      & > .menu-toggle {
        color: $white;
        height: 46px;
        .fa::before {
          content: '\f0d7';
        }
        span:hover {
          background-color: rgba(#fff, 0.1);
        }
      }
      &:hover > .menu-link {
        background-color: rgba($white, 0.2);
      }
    }
  }
}

.menu-btn {
  background: 0;
  border: 0;
  background: 0;
  padding: 0;
  margin: 8px 0;
  height: 30px;
  width: 46px;
  color: $white;
  font-size: 24px;
  line-height: 30px;
}

.search {
  position: relative;
  padding: 0 20px;
  margin-bottom: 10px;
  &__btn {
    display: flex;
    width: 40px;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: $gray-600;
    position: absolute;
    top: 0;
    right: 20px;
    background: 0;
    border: 0;
  }
  @media (min-width: 1200px) {
    display: none;
    padding: 5px 0;
    width: 300px;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    &__btn {
      right: 0;
    }
  }
}

.search-btn {
  @media (max-width: 1199px) {
    display: none !important;
  }
}
