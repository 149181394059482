.partner-slider {
  padding: 0 30px;
  margin-bottom: 50px;
  position: relative;
  &__iwrap {
    display: block;
    position: relative;
    border: 1px solid $border-color;
    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 50%;
    }
    img {
      display: block;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      position: absolute;
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
  &__prev,
  &__next {
    display: flex;
    width: 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    &:hover {
      background-color: rgba(#000, 0.02);
      cursor: pointer;
    }
    &:focus {
      outline: 0;
    }
  }
  &__next {
    left: auto;
    right: 0;
  }
}

.news-slider {
  position: relative;
  margin-bottom: 40px;
  &__prev,
  &__next {
    padding: 20px 5px;
    position: absolute;
    top: calc(50% - 29px);
    left: -40px;
    transform: translateY(-50%);
    &:focus {
      outline: 0;
      cursor: pointer;
    }
    img {
      width: 18px;
    }
  }
  &__next {
    left: auto;
    right: -40px;
    img {
      transform: rotateY(180deg);
    }
  }
  @media (max-width: 575px) {
    &__prev,
    &__next {
      img {
        width: 10px;
      }
    }
    &__prev {
      left: -15px;
    }
    &__next {
      right: -15px;
    }
  }
}

.aside-slider {
  position: relative;
  &__iwrap {
    @include iwrap(120%);
  }
  &__text {
    display: flex;
    align-items: center;
    min-height: 86px;
    color: $white;
    background-color: $primary;
    text-align: center;
    padding: 8px 5px;
  }
  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $black;
    color: $white;
    position: absolute;
    z-index: 10;
    top: calc(50% - 43px);
    left: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.3;
    &:hover {
      opacity: 0.5;
    }
    &:focus {
      outline: 0;
    }
  }
  &__next {
    left: auto;
    right: 10px;
  }
}
