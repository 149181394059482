body {
  font-family: Arial, Helvetica, sans-serif;
}
img {
  max-width: 100%;
}
button {
  cursor: pointer;
}
a,
button,
input,
textarea,
select {
  &:focus {
    outline: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}
