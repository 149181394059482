.post {
  &-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 18px;
  }
  &-sapo {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 26px;
    text-align: justify;
  }
}

.teacher-detail {
  &__thumb {
    width: 250px;
    margin-right: 30px;
    margin-bottom: 20px;
  }
  &__name {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
    margin-top: 14px;
    font-weight: 600;
  }
  &__pos {
    text-align: center;
    font-size: 14px;
  }
  &__content {
    margin-bottom: 20px;
    p {
      text-align: justify;
    }
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    &__thumb {
      width: 100%;
      margin-right: 0;
    }
  }
}

.teacher-nav {
  margin-bottom: 35px;
  justify-content: center;
  padding: 1px;
  .nav-link {
    padding: 10px 20px;
    background-color: $white;
    box-shadow: 0 0 0 1px $border-color;
    &.active {
      background-color: $primary;
      color: $white;
    }
  }
  .nav-item:first-child .nav-link {
    border-radius: 4px 0 0 4px;
  }
  .nav-item:last-child .nav-link {
    border-radius: 0 4px 4px 0;
  }
  @media (max-width: 991px) {
    .nav-item {
      width: 100%;
    }
    .nav-link {
      text-align: center;
    }
    .nav-item:first-child .nav-link {
      border-radius: 4px 4px 0 0;
    }
    .nav-item:last-child .nav-link {
      border-radius: 0 0 4px 4px;
    }
  }
}

.post-2 {
  &-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  &-info {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
}

.relate {
  &__title {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    &::after {
      content: '';
      display: block;
      width: 65px;
      border-bottom: 2px solid $primary;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  &__list {
    padding-left: 17px;
    list-style-type: square;
  }
  &__link {
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: $gray-800;
    &:hover {
      color: $primary;
    }
  }
}
