@mixin img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}
@mixin img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 50% 50%;
}
@mixin iwrap($percent) {
  display: block;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: $percent;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
}
@mixin hover-zoom {
  overflow: hidden;
  img {
    transition: .3s;
  }
  &:hover img {
    transform: scale(1.05);
  }
}

// add limit line for text
@mixin max-line ($line) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: $line-height-base;
  @each $val in $line {
    -webkit-line-clamp: #{$val};
    max-height: #{$val * $line-height-base}em;
  }
}
