.teacher {
  &__iwrap {
    @include iwrap(100%);
  }
  &__body {
    border: 1px solid $border-color;
    border-top: 0;
    padding: 12px;
    font-size: 14px;
    line-height: 24px;
  }
}

.gallery {
  position: relative;
  &__iwrap {
    @include iwrap(75%);
    @include hover-zoom;
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0;
    &:hover {
      color: $primary;
    }
  }
  &__info {
    color: $gray-700;
    a {
      color: inherit;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  &__icon {
    display: flex;
    width: 60px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background-color: $blue-lighten;
    color: $white;
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 24px;
  }
  &__number {
    font-size: 14px;
    color: $white;
    text-shadow:
      1px 1px $gray-800,
      1px -1px $gray-800,
      -1px 1px $gray-800,
      -1px -1px $gray-800;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  @media (max-width: 575px) {
    &__icon {
      width: 40px;
      height: 30px;
      font-size: 16px;
    }
  }
}

.news {
  &__iwrap {
    @include iwrap(67%);
    @include hover-zoom;
  }
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0;
    &:hover {
      color: $primary;
    }
  }
  &__info {
    color: $gray-700;
    a {
      color: inherit;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  &__cate {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    line-height: 32px;
    padding: 0 19px;
    background-color: $blue-lighter;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  &__desc {
    @include max-line(3);
    text-align: justify;
    margin-top: 8px;
  }
  @media (max-width: 575px) {
    &__cate {
      font-size: 12px;
      padding: 0 10px;
      line-height: 20px;
    }
  }
}

.news-3 {
  &__iwrap {
    @include iwrap(70%);
    @include hover-zoom;
    width: 300px;
    margin-right: 30px;
  }
  &__title {
    font-size: 24px;
    line-height: 1.2;
    font-weight: 600;
    margin: 8px 0;
    &:hover {
      color: $primary;
    }
  }
  &__info {
    color: $gray-700;
    a {
      color: inherit;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  &__desc {
    @include max-line(4);
    text-align: justify;
    margin-top: 8px;
  }
  &__cate {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    line-height: 32px;
    padding: 0 19px;
    background-color: $blue-lighter;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  @media (max-width: 991px) {
    &__title {
      font-size: 16px;
      line-height: 1.5;
    }
  }
  @media (max-width: 575px) {
    flex-wrap: wrap;
    &__iwrap {
      width: 100%;
      margin-right: 0;
    }
    &__title {
      font-size: 14px;
    }
  }
}

.news-4 {
  &__iwrap {
    @include iwrap(56.25%);
  }
  &__title {
    font-size: 24px;
    font-weight: 600;
    margin: 20px 0 18px;
    &:hover {
      color: $primary;
    }
  }
  &__info {
    color: $gray-700;
    a {
      color: inherit;
      &:hover {
        color: $primary;
        text-decoration: none;
      }
    }
  }
  &__cate {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    line-height: 32px;
    padding: 0 19px;
    background-color: $blue-lighter;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  &__desc {
    @include max-line(2);
    text-align: justify;
    margin-top: 8px;
  }
  @media (max-width: 575px) {
    &__title {
      font-size: 20px;
      margin: 12px 0;
    }
    &__cate {
      font-size: 12px;
      padding: 0 10px;
      line-height: 20px;
    }
  }
}
